import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-place-detail',
  templateUrl: './place-detail.component.html',
  styleUrls: ['./place-detail.component.scss']
})
export class PlaceDetailComponent implements OnInit {


  placeObjData: any;
  travelGuide: any;
  bannerImg: string;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {


    console.log(this.route.snapshot.paramMap.get('id'));
    this.bannerImg = '../../../assets/images/' + this.route.snapshot.paramMap.get('id').replace(/\s/g, "") + '.jpg';
    this.onGetPlace(this.route.snapshot.paramMap.get('id'));

    this.travelGuide =
      [
        {
          title: 'Five Tips for Low Cost Vacations',
          description: 'The availability of low-cost flights and last minute internet deals means that cheap vacations are far easier to come by than they ever used to be, but it can still take a bit of shopping around to get the best discounts. In this article we list the five best tips for getting a low cost vacation.',
          image: 'https://demo.travolia.com/wp-content/uploads/low-cost-vacation-400x250.jpg',
        },
        {
          title: 'How to Avoid Jet Lag',
          description: 'There’s nothing more aggravating than arriving at your destination groggy and red-eyed. Jet lag makes clear thinking difficult, leading to bad decisions at international business meetings, and recreational travelers find it detracts from the enjoyment of their hard-earned and time-limited vacations.',
          image: 'https://demo.travolia.com/wp-content/uploads/jet-lag-400x250.jpg',
        },
        {
          title: 'Travel Tips for Booking a Cheap Orlando Vacation',
          description: 'The availability of low-cost flights and lOrlando, Florida is a vacationer’s paradise. Contrary to popular belief, you don’t have to have children to enjoy the theme parks and attractions in Orlando, which include Disney World, Universal Studios, Epcot, Disney’s Animal Kingdom, and the Disney’s Movie Studios.',
          image: 'https://demo.travolia.com/wp-content/uploads/disney-world-400x250.jpg',
        }
      ];
  }

  ngOnInit() {
  }

  onGetPlace(cityName: string) {
    this.http.get('../../assets/json/places/' + cityName.replace(/\s/g, '') + '.json').subscribe(resData => {
      this.placeObjData = resData;
    });
  }

}
