import { Component, OnInit } from "@angular/core";
import { ConversionService } from "../services/conversion.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private conversionService: ConversionService) {}

  trackPhoneClick(): void {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "phoneClick", {});
  }

  ngOnInit(): void {}
}
