<!-- Main Block -->
<div class="container-fluid primary-bg banner">
  <div class="row">
    <div class="col-md-10 col-11 mx-auto mt-5 mb-5 text-light">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="d-flex justify-content-center flex-column">
            <div class="d-none d-sm-block d-md-block d-lg-block">
              <h1 class="main-head-display">
                Compare and Book Cheap Flights on Over 600 Airlines!
              </h1>

              <p class="lead-text lead">
                flightreservationsdesk is a travel chain that works in
                collaboration with various well acclaimed travel partners to
                offer you the best travel and stay deals. Access our special
                services and deals to make your journey worth a frame!
              </p>

              <p class="lead-text lead text-2">
                Change & Cancel Airlines Flights on Discounts <br />
                Unpublished Airlines Flights Deals <br />
                Agents Direct Line - No IVR No Hold Time. Call Now
              </p>
              <br />
              <a href="tel:18778040909" class="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  ></path>
                </svg>
                18778040909
              </a>
            </div>

            <div class="d-block d-sm-none">
              <div style="font-size: 22px; font-weight: 500">
                Change & Cancel Airlines Flights on Discounts <br />
                Unpublished Airlines Flights Deals <br />
                Agents Direct Line - <br />
                No IVR No Hold Time. <br />
                Call Now
              </div>
              <br />
              <a href="tel:18778040909" class="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  ></path>
                </svg>
                18778040909
              </a>
            </div>

            <br />
            <div
              class="brands d-flex justify-content-center justify-content-lg-around"
            >
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=4426&u=https%3A%2F%2Fwww.cheapoair.com"
                  target="_blank"
                  ><img src="../../../../assets/images/146.png" alt="cheapoair"
                /></a>
              </div>
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=4115&u=https%3A%2F%2Fhotellook.com"
                  target="_blank"
                  ><img src="../../../../assets/images/101.png" alt="hotellook"
                /></a>
              </div>
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=3555&u=https%3A%2F%2Fwww.discovercars.com%2F"
                  target="_blank"
                  ><img
                    src="../../../../assets/images/117.png"
                    alt="discover car"
                /></a>
              </div>
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=4114&u=https%3A%2F%2Fwww.aviasales.ru"
                  target="_blank"
                  ><img src="../../../../assets/images/100.png" alt="cheapoair"
                /></a>
              </div>
              <div>
                <a
                  href="https://www.amazon.com/s/ref=as_li_ss_tl?k=travel+accessories&crid=171DA4NSL71A6&sprefix=travel+acc,aps,480&ref=nb_sb_ss_i_1_10&linkCode=ll2&tag=usahealthmyst-20&linkId=78f10faaec3f8e76139f72618a06c6a6&language=en_US"
                  target="_blank"
                >
                  <img src="../../../../assets/images/amazon.png" alt="Amazon"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col home-img text-center text-md-right text-lg-right">
          <div class="img-bg d-none d-md-block d-lg-block"></div>
          <img
            src="../../../../assets/images/flights.jpg"
            alt="img"
            class="rounded-circle"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="svg-img w-100">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#4a6649"
      fill-opacity="1"
      d="M0,288L34.3,282.7C68.6,277,137,267,206,240C274.3,213,343,171,411,176C480,181,549,235,617,213.3C685.7,192,754,96,823,69.3C891.4,43,960,85,1029,90.7C1097.1,96,1166,64,1234,42.7C1302.9,21,1371,11,1406,5.3L1440,0L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
    ></path>
  </svg>
</div>
<!-- Main Block -->

<section class="container-fulid mt-5">
  <div class="row">
    <div class="col-md-10 col-11 mx-auto mt-5">
      <div class="row">
        <!-- Left Block -->
        <div class="col-md-9">
          <h2 class="head-2">Affordable flights deals . . .</h2>
          <div *ngFor="let item of flights | keyvalue">
            <div class="card mb-5" *ngFor="let item of item.value | keyvalue">
              <a
                href="https://c146.travelpayouts.com/click?shmarker=290991&promo_id=4410&source_type=link&type=click"
                target="_blank"
                class="head-2 text-info"
                >{{ item.key }}</a
              >
              <div class="row no-gutters">
                <div
                  class="col-md-4 col-lg-4 col-12"
                  *ngFor="let item of item.value"
                >
                  <p class="text-muted">
                    <i class="fa fa-check mr-1"></i> {{ item }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Left Block -->

        <!-- Right Block -->
        <div class="col-md-3">
          <h2 class="head-2">Find the best deals . . .</h2>
          <div class="widget right-widget">
            <!-- <iframe scrolling="no" width="100%" frameborder="0"
              src="//www.travelpayouts.com/widgets/928d79456528e9ee4cccde65f644664e.html?v=2055"></iframe> -->

            <!-- <div class="svg-img-right">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#4a6649" fill-opacity="1"
                  d="M0,288L34.3,282.7C68.6,277,137,267,206,240C274.3,213,343,171,411,176C480,181,549,235,617,213.3C685.7,192,754,96,823,69.3C891.4,43,960,85,1029,90.7C1097.1,96,1166,64,1234,42.7C1302.9,21,1371,11,1406,5.3L1440,0L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z">
                </path>
              </svg>
            </div> -->
            <div class="p-3 text-center">
              <p class="mb-4">
                We get commissions for purchases made through links in this
                post.
              </p>
              <p>
                For any assistance, <br />
                reach flightreservationsdesk customer care @ <br />
                <b> <a href="tel:+18778040909">1888-474-0123</a></b>
              </p>
            </div>
          </div>
        </div>
        <!-- Right Block -->
      </div>
    </div>
  </div>
</section>
