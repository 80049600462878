import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PlaceComponent } from "./place/place.component";
import { PolicyComponent } from "./policy/policy.component";
import { TermsandconditionsComponent } from "./termsandconditions/termsandconditions.component";
import { AffiliateComponent } from "./affiliate/affiliate.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";

import { HOME_PATH } from "./shared/routes.constant";
import { AirlinesComponent } from "./airlines/airlines.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: HOME_PATH,
    pathMatch: "full",
  },
  {
    path: "home",
    component: PlaceComponent,
  },
  {
    path: "airlines-flights",
    component: AirlinesComponent,
  },
  // {
  //   path: "places/:id",
  //   component: PlaceDetailComponent,
  // },
  // {
  //   path: "flights",
  //   component: FlightsComponent,
  // },
  // {
  //   path: "hotels",
  //   component: HotelsComponent,
  // },
  // {
  //   path: "shop",
  //   component: ShopComponent,
  // },
  {
    path: "policy",
    component: PolicyComponent,
  },
  {
    path: "termsandconditions",
    component: TermsandconditionsComponent,
  },
  {
    path: "about",
    component: AboutComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "affiliate",
    component: AffiliateComponent,
  },
  {
    path: "**",
    component: NotfoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
