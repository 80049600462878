import { Component, OnInit } from "@angular/core";
import { ConversionService } from "../services/conversion.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(private conversionService: ConversionService) {}

  trackPhoneClick(): void {
    console.log(window.uetq);
    window.uetq = window.uetq || [];
    window.uetq.push("event", "phoneClick", {});
  }

  ngOnInit(): void {}
}
