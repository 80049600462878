import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-airlines",
  templateUrl: "./airlines.component.html",
  styleUrls: ["./airlines.component.scss"],
})
export class AirlinesComponent implements OnInit {
  placesData: any;
  travelAccessories: any;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loadScript();
    this.loadPopularRoutes();
    this.flightSchedule();
    this.calendar();
    this.titleService.setTitle("Flight Reservations Desk");
    this.meta.addTag({
      name: "author",
      content: "flightreservationsdesk",
    });
    this.meta.updateTag({
      name: "description",
      content:
        "flightreservationsdesk is a travel chain that works in collaboration with various well acclaimed travel partners to offer you the best travel and stay deals. Access our special services and deals to make your journey worth a frame!",
    });

    this.fetchProducts();
  }

  trackPhoneClick(): void {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "phoneClick", {});
  }

  public loadScript() {
    let body = <HTMLDivElement>document.getElementById("travelpayouts");
    let script = document.createElement("script");
    script.innerHTML = "";
    script.src =
      "//www.travelpayouts.com/widgets/8f03276aa5d327b66b0bcdc1c09ba56d.js?v=2209";
    script.async = true;
    script.defer = true;
    script.charset = "utf-8";
    body.appendChild(script);
  }

  loadPopularRoutes() {
    let body = <HTMLDivElement>document.getElementById("popularRoutes");
    let script = document.createElement("script");
    script.innerHTML = "";
    script.src =
      "https://tp.media/content?trs=164442&shmarker=356552&target_host=www.aviasales.com%2Fsearch&locale=en&currency=usd&limit=6&primary=%230085FF&promo_id=4044&campaign_id=100";
    script.async = true;
    script.defer = true;
    script.charset = "utf-8";
    body.appendChild(script);
  }

  flightSchedule() {
    let body = <HTMLDivElement>document.getElementById("schedule");
    let script = document.createElement("script");
    script.innerHTML = "";
    script.src =
      "https://tp.media/content?trs=164442&shmarker=356552&color_button=%23FF0000&target_host=www.aviasales.com%2Fsearch&locale=en&origin=LON&destination=BKK&with_fallback=false&non_direct_flights=true&min_lines=5&border_radius=0&color_background=%23FFFFFF&color_text=%23000000&color_border=%23FFFFFF&promo_id=2811&campaign_id=100";
    script.async = true;
    script.defer = true;
    script.charset = "utf-8";
    body.appendChild(script);
  }

  calendar() {
    let body = <HTMLDivElement>document.getElementById("calendar");
    let script = document.createElement("script");
    script.innerHTML = "";
    script.src =
      "https://tp.media/content?trs=164442&shmarker=356552&searchUrl=www.aviasales.com%2Fsearch&locale=en&currency=usd&origin=LON&destination=BKK&one_way=false&only_direct=false&period=year&range=7%2C14&primary=%230C73FE&color_background=%23FFFFFF&dark=%23000000&light=%23FFFFFF&achieve=%2345AD35&promo_id=4041&campaign_id=100";
    script.async = true;
    script.defer = true;
    script.charset = "utf-8";
    body.appendChild(script);
  }

  fetchProducts() {
    this.http
      .get("./../assets/json/products/travelproducts.json")
      .subscribe((resData) => {
        this.travelAccessories = resData;
      });
  }
}
