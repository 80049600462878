<!-- Main Block -->
<div class="container-fluid mt-5 bg-box">
  <div class="bg">&nbsp;</div>
  <div class="row">
    <div class="col-md-8 col-10 mx-auto py-5 text-center text-light">
      <h1 class="main-head-display mt-md-5 title">
        Change &amp; Cancel Flight Reservations on Discounts, <br />
        Unpublished Flight Reservations Deals <br />
        Agents Direct Line - No IVR No Hold Time. Call Now
        <a href="tel:+18778040909" class="btn-cta">
          <i class="fa-solid fa-phone"></i>
          1877-804-0909
        </a>
      </h1>
      <div id="travelpayouts"></div>
    </div>
  </div>
</div>

<!-- Main Block -->
<section class="band">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <ul class="d-flex justify-content-center align-items-center">
          <li class="text-light">Best Discounts</li>
          <li class="text-light">Easy Bookings</li>
          <li class="text-light">Best Discounts</li>
          <li class="text-light">Coupons</li>
          <li class="text-light">Call Agent Direct</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="container-fulid my-5">
  <div class="head-9"></div>
  <div class="row">
    <div class="col-md-10 col-10 mx-auto mt-5">
      <div class="row">
        <div class="col-md-12 mb-5 text-center">
          <h2 class="head-2">
            Over thousands of customers use flightreservationsdesk as their
            preferred travel planner!
          </h2>
        </div>
        <div class="col-md-3">
          <div class="text-center info-box">
            <i class="fa fa-search"></i>
            <h2>Search and Save</h2>
            <p>
              Save up to 60% by comparing prices from hundreds of travel sites
              at once.
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="text-center info-box bg-light shadow-sm">
            <i class="fa fa-check-square"></i>
            <h2>Price Guarantee</h2>
            <p>
              There are no extra charges or hidden fees. The price you see is
              what you pay.
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="text-center info-box">
            <i class="fa fa-briefcase"></i>
            <h2>Easy Bookings</h2>
            <p>
              Our travel search engine is the easiest and cheapest way to book
              your next trip.
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="text-center info-box bg-light shadow-sm">
            <i class="fa fa-globe-americas"></i>
            <h2>Explore the World</h2>
            <p>Plan your trips with our Experienced Agents available 24*7</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-md-10 col-11 mx-auto">
      <!-- Block -->
      <div class="row">
        <div class="col-md-6">
          <h2 class="head-2">COME SEE WHAT YOU'VE BEEN MISSING</h2>
          <p class="text-muted">
            Services At flightreservationsdesk.com our mission is to help <br />
            domestic and international travelers to book flights and make <br />
            changes and cancellations at discounted rates.
          </p>
          <br /><br />
          <a href="tel:18778040909" class="btn-frd"
            ><i class="fa-solid fa-phone"></i> 18778040909</a
          >
        </div>
        <div class="col-md-6">
          <h2 class="head-2">Most Visited . . .</h2>
          <div id="popularRoutes"></div>
        </div>
      </div>
      <!-- /Block -->
      <br />
      <br />
      <!-- Block -->
      <div class="row">
        <div class="col-md-12 text-center">
          <div id="schedule"></div>
          <br /><br />
          <a href="tel:18778040909" class="btn-frd"
            ><i class="fa-solid fa-phone"></i> 18778040909</a
          >
        </div>
      </div>
      <!-- /Block -->
      <br />
      <br />
      <br />
      <br />
      <!-- Block -->
      <div class="row">
        <div class="col-md-6">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="d-flex align-items-center justify-content-left">
            <div>
              <h2 class="head-2">Travel Simply and Economically</h2>
              <p class="text-muted">
                Rest assured we will Provide Superb Customer Service
              </p>
              <br /><br />
              <a href="tel:18778040909" class="btn-frd"
                ><i class="fa-solid fa-phone"></i> 18778040909</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div id="calendar"></div>
        </div>
      </div>
      <!-- /Block -->

      <!-- Block -->
      <div class="row">
        <div class="col-md-6">
          <h2 class="head-2">More Flight Deals</h2>

          <ul class="d-flex flex-wrap">
            <li>Flights Under $100</li>
            <li>Flights Under $50</li>
            <li>Flights Under $30</li>
            <li>Business Class Flights Deals</li>
            <li>First Class</li>
            <li>Flights Deals</li>
            <li>Last Minute Flights</li>
            <li>Domestic Flights</li>
            <li>One Way</li>
            <li>flights Multi City</li>
            <li>Flights Cheap</li>
            <li>Flight Deals</li>
            <li>International Flights</li>
            <li>Round Trip Flights</li>
            <li>Non Stop Flights</li>
            <li>Premium Economy Flights</li>
          </ul>
        </div>
        <div class="col-md-6">
          <h2 class="head-2">Popular Airlines</h2>

          <ul class="d-flex flex-wrap">
            <li>Alaska Airlines</li>
            <li>JetBlue Airlines</li>
            <li>United Airlines</li>
            <li>Delta Air Lines</li>
            <li>American Airlines</li>
            <li>Emirates</li>
            <li>Philippine Airlines</li>
            <li>British Airways</li>
            <li>Southwest Airlines</li>
            <li>Lufthansa</li>
            <li>Virgin Atlantic</li>
            <li>Air France</li>
            <li>KLM Royal Dutch Airlines</li>
            <li>Qatar Airways</li>
            <li>Singapore Airlines</li>
            <li>Cathay Pacific Airways</li>
            <li>Qantas Airways</li>
            <li>Turkish Airlines</li>
            <li>Air Canada</li>
          </ul>
        </div>
      </div>
      <!-- /Block -->

      <!-- Block -->
      <div class="row">
        <div class="col-md-12 text-center">
          <br />
          <br />

          <div class="d-flex w-100 justify-content-center">
            <div>
              <h2 class="head-2">Contact Help Center</h2>
              <p class="text-muted">
                If there's an easier way to get you there, we'll be the first to
                tell you. Flying with us is more than just flying.
              </p>
              <p>
                Write to us:
                <a href="mailto:support@flightreservationsdesk.com"
                  >support@flightreservationsdesk.com</a
                >
              </p>
              <br /><br />
              <a href="tel:18778040909" class="btn-frd"
                ><i class="fa-solid fa-phone"></i> 18778040909</a
              >
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <!-- /Block -->
    </div>
  </div>
</section>
