<!-- Main Block -->
<div class="container-fluid primary-bg">
  <div class="row">
    <div class="col-md-10 col-11 mx-auto mt-5 mb-2 text-light">
      <div class="row">
        <div class="col-md-6 col-12">
          <h1 class="main-head-display">
            We know what you need, see if you agree! Shopping made easy!
          </h1>
          <p class="lead-text lead">
            flightreservationsdesk is a travel chain that works in collaboration
            with various well acclaimed travel partners to offer you the best
            travel and stay deals. Access our special services and deals to make
            your journey worth a frame!
          </p>

          <div
            class="brands d-flex justify-content-center justify-content-lg-around"
          >
            <div>
              <a
                href="https://tp.media/r?marker=290991&p=4426&u=https%3A%2F%2Fwww.cheapoair.com"
                target="_blank"
                ><img src="../../../../assets/images/146.png" alt="cheapoair"
              /></a>
            </div>
            <div>
              <a
                href="https://tp.media/r?marker=290991&p=4115&u=https%3A%2F%2Fhotellook.com"
                target="_blank"
                ><img src="../../../../assets/images/101.png" alt="hotellook"
              /></a>
            </div>
            <div>
              <a
                href="https://tp.media/r?marker=290991&p=3555&u=https%3A%2F%2Fwww.discovercars.com%2F"
                target="_blank"
                ><img
                  src="../../../../assets/images/117.png"
                  alt="discover car"
              /></a>
            </div>
            <div>
              <a
                href="https://tp.media/r?marker=290991&p=4114&u=https%3A%2F%2Fwww.aviasales.ru"
                target="_blank"
                ><img src="../../../../assets/images/100.png" alt="aviasales"
              /></a>
            </div>
            <div>
              <a
                href="https://www.amazon.com/s/ref=as_li_ss_tl?k=travel+accessories&crid=171DA4NSL71A6&sprefix=travel+acc,aps,480&ref=nb_sb_ss_i_1_10&linkCode=ll2&tag=usahealthmyst-20&linkId=78f10faaec3f8e76139f72618a06c6a6&language=en_US"
                target="_blank"
              >
                <img src="../../../../assets/images/amazon.png" alt="Amazon"
              /></a>
            </div>
          </div>
        </div>
        <div class="col home-img text-center text-md-right text-lg-right">
          <div class="img-bg d-none d-md-block d-lg-block"></div>
          <img
            src="../../../../assets/images/shopping.jpg"
            alt="img"
            class="rounded-circle"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="svg-img w-100">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#4a6649"
      fill-opacity="1"
      d="M0,288L34.3,282.7C68.6,277,137,267,206,240C274.3,213,343,171,411,176C480,181,549,235,617,213.3C685.7,192,754,96,823,69.3C891.4,43,960,85,1029,90.7C1097.1,96,1166,64,1234,42.7C1302.9,21,1371,11,1406,5.3L1440,0L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
    ></path>
  </svg>
</div>
<!-- Main Block -->

<section class="container-fulid mt-5">
  <div class="row">
    <div class="col-md-10 col-11 mx-auto mt-5">
      <div class="row">
        <!-- Left Block -->
        <div class="col-md-9" *ngIf="travelAccessories">
          <h2 class="head-2">All that you need . . .</h2>

          <div
            class="card mb-5 traveCard"
            *ngFor="let item of travelAccessories"
          >
            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="card-img-box">
                  <img
                    [src]="item.image"
                    class="card-img"
                    alt="{{ item.image }}"
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="card-body">
                  <h5 class="card-title">{{ item.title }}</h5>
                  <p class="card-text">{{ item.description }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="card-body h-100 d-flex justify-content-center align-items-center flex-column"
                >
                  <p class="card-text text-success">
                    <b>$ {{ item.price }}</b>
                  </p>
                  <a
                    href="{{ item.url }}"
                    class="btn btn-primary btn-amazon"
                    target="_blank"
                    >Available in Amazon</a
                  >
                  <p class="card-text mt-3">{{ item.reviews }}</p>
                  <p class="card-text mt-3">{{ item.rating }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Left Block -->

        <!-- Right Block -->
        <div class="col-md-3">
          <div class="widget">
            <h2 class="head-2">Find the best deals . . .</h2>

            <div class="mb-5">
              <a
                target="_blank"
                href="https://www.amazon.com/promocode/A2GZUSN6UF4HT1?ref_=assoc_tag_ph_1524215270585&_encoding=UTF8&camp=1789&creative=9325&linkCode=pf4&tag=usahealthmyst-20&linkId=b81c107a7295bc787d19df44a56d980f"
                >Save 80.0% on select products from Jesaisque with promo code
                80EXK2S5, through 8/4 while supplies last.</a
              ><img
                src="//ir-na.amazon-adsystem.com/e/ir?t=usahealthmyst-20&l=pf4&o=1"
                width="1"
                height="1"
                border="0"
                alt=""
                style="border: none !important; margin: 0px !important"
              />
            </div>

            <hr />

            <iframe
              src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=12&l=ur1&category=freetime&banner=1AM3MVNDNXNJC2Y46DR2&f=ifr&lc=pf4&linkID=2c2c8661aa2fbf498b744f29c8a0c348&t=usahealthmyst-20&tracking_id=usahealthmyst-20"
              width="100%"
              scrolling="no"
              border="0"
              marginwidth="0"
              style="border: none"
              frameborder="0"
            ></iframe>

            <div class="p-3 text-center">
              <p class="mb-4">
                We get commissions for purchases made through links in this
                post.
              </p>
            </div>
            <br /><br />
          </div>
        </div>
        <!-- Right Block -->
      </div>
    </div>
  </div>
</section>
