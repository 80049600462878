<!-- Main Block -->
<div class="container-fluid primary-bg">
  <div class="row">
    <div class="col-md-10 col-11 mx-auto mt-5 mb-5 text-light">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="d-flex justify-content-center flex-column">
            <h1 class="main-head-display">Affordable luxury all year round!</h1>
            <p class="lead-text lead">
              flightreservationsdesk is a travel chain that works in
              collaboration with various well acclaimed travel partners to offer
              you the best travel and stay deals. Access our special services
              and deals to make your journey worth a frame!
            </p>
            <div
              class="brands d-flex justify-content-center justify-content-lg-around"
            >
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=4426&u=https%3A%2F%2Fwww.cheapoair.com"
                  target="_blank"
                  ><img src="../../../../assets/images/146.png" alt="cheapoair"
                /></a>
              </div>
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=4115&u=https%3A%2F%2Fhotellook.com"
                  target="_blank"
                  ><img src="../../../../assets/images/101.png" alt="hotellook"
                /></a>
              </div>
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=3555&u=https%3A%2F%2Fwww.discovercars.com%2F"
                  target="_blank"
                  ><img
                    src="../../../../assets/images/117.png"
                    alt="discover car"
                /></a>
              </div>
              <div>
                <a
                  href="https://tp.media/r?marker=290991&p=4114&u=https%3A%2F%2Fwww.aviasales.ru"
                  target="_blank"
                  ><img src="../../../../assets/images/100.png" alt="cheapoair"
                /></a>
              </div>
              <div>
                <a
                  href="https://www.amazon.com/s/ref=as_li_ss_tl?k=travel+accessories&crid=171DA4NSL71A6&sprefix=travel+acc,aps,480&ref=nb_sb_ss_i_1_10&linkCode=ll2&tag=usahealthmyst-20&linkId=78f10faaec3f8e76139f72618a06c6a6&language=en_US"
                  target="_blank"
                >
                  <img src="../../../../assets/images/amazon.png" alt="Amazon"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col home-img text-center text-md-right text-lg-right">
          <div class="img-bg d-none d-md-block d-lg-block"></div>
          <img
            src="../../../../assets/images/hotels.jpg"
            alt="img"
            class="rounded-circle"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="svg-img w-100">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#4a6649"
      fill-opacity="1"
      d="M0,288L34.3,282.7C68.6,277,137,267,206,240C274.3,213,343,171,411,176C480,181,549,235,617,213.3C685.7,192,754,96,823,69.3C891.4,43,960,85,1029,90.7C1097.1,96,1166,64,1234,42.7C1302.9,21,1371,11,1406,5.3L1440,0L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
    ></path>
  </svg>
</div>
<!-- Main Block -->

<section class="container-fulid mt-5">
  <div class="row">
    <div class="col-md-10 col-11 mx-auto mt-5">
      <div class="row">
        <!-- Left Block -->
        <div class="col-md-9">
          <h2 class="head-2">Highly Rated Stays at . . .</h2>

          <div class="card mb-5" *ngFor="let item of hotelsData">
            <div class="row no-gutters">
              <div class="col-md-4">
                <img
                  [src]="item.image"
                  class="card-img"
                  alt="{{ item.image }}"
                />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">{{ item.name }}</h5>
                  <h5 class="card-title text-warning">
                    Rating: {{ item.rating }}
                  </h5>
                  <p class="card-text text-muted">{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Left Block -->

        <!-- Right Block -->
        <div class="col-md-3">
          <h2 class="head-2">Find the best deals . . .</h2>
          <div class="widget right-widget">
            <iframe
              scrolling="no"
              width="100%"
              frameborder="0"
              src="//www.travelpayouts.com/widgets/ab698b52dc51e707477f53193b3000e3.html?v=2054"
            ></iframe>

            <div class="svg-img-right">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="#4a6649"
                  fill-opacity="1"
                  d="M0,288L34.3,282.7C68.6,277,137,267,206,240C274.3,213,343,171,411,176C480,181,549,235,617,213.3C685.7,192,754,96,823,69.3C891.4,43,960,85,1029,90.7C1097.1,96,1166,64,1234,42.7C1302.9,21,1371,11,1406,5.3L1440,0L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
                ></path>
              </svg>
            </div>

            <div class="p-3 text-center">
              <p class="mb-4">
                We get commissions for purchases made through links in this
                post.
              </p>
              <p>
                For any assistance, <br />
                reach flightreservationsdesk customer care @ <br />
                <b> <a href="tel:+18778040909">1888-474-0123</a></b>
              </p>
            </div>
          </div>
        </div>
        <!-- Right Block -->
      </div>
    </div>
  </div>
</section>
