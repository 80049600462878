import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-hotels",
  templateUrl: "./hotels.component.html",
  styleUrls: ["./hotels.component.scss"],
})
export class HotelsComponent implements OnInit {
  hotelsData: any;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.titleService.setTitle(
      "flightreservationsdesk: find the best hotels deals"
    );
    this.meta.addTag({
      name: "author",
      content: "Sanjay Kumar",
    });
    this.meta.updateTag({
      name: "description",
      content:
        "flightreservationsdesk help people to plan their route" +
        " flightreservationsdesk help people to plan their route",
    });

    this.fetchHotels();
  }

  trackPhoneClick(): void {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "phoneClick", {});
  }

  fetchHotels() {
    this.http
      .get("./../assets/json/hotels/hotels.json")
      .subscribe((resData) => {
        this.hotelsData = resData;
      });
  }
}
