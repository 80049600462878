<div class="container-fulid">
  <div class="row">
    <div class="col-md-12 col-12 col-lg-12">
      <div class="banner">
        <div class="d-flex justify-content-center p-5 align-items-center h-100">
          <div class="display-1 text-center">
            404
            <br />
            Page Not Found!
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
