import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-flights",
  templateUrl: "./flights.component.html",
  styleUrls: ["./flights.component.scss"],
})
export class FlightsComponent implements OnInit {
  flights: any;
  date = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());

  myControl = new FormControl();
  options: any = [
    { city: "Delhi", iata: "del" },
    { city: "kanpur", iata: "cnb" },
    { city: "lucknow", iata: "luc" },
  ];
  filteredOptions: Observable<string[]>;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.fetchIATA();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );

    this.titleService.setTitle(
      "flightreservationsdesk: affordable flights deals"
    );
    this.meta.addTag({
      name: "author",
      content: "Sanjay Kumar",
    });
    this.meta.updateTag({
      name: "description",
      content:
        "flightreservationsdesk help people to plan their route" +
        " flightreservationsdesk help people to plan their route",
    });

    this.fetchFlights();
    // this.fetchAirlines();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.city.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  trackPhoneClick(): void {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "phoneClick", {});
  }

  fetchFlights() {
    this.http
      .get("./../assets/json/flights/flights.json")
      .subscribe((resData) => {
        console.log(resData);
        this.flights = resData;
      });
  }
  fetchIATA() {
    this.http.get("./../assets/json/iata.json").subscribe((resData) => {
      console.log(resData);
      // this.options = resData;
    });
  }

  fetchAirlines() {
    this.http
      .get(
        "https://travelpayouts-travelpayouts-flight-data-v1.p.rapidapi.com/data/en-GB/airlines.json",
        {
          headers: {
            "x-access-token": "d147b890e75c2250c7bf074cfe02ad2a",
            "x-rapidapi-host":
              "travelpayouts-travelpayouts-flight-data-v1.p.rapidapi.com",
            "x-rapidapi-key":
              "be728614bbmsh6dadf2afc1b8328p1b438ejsn975a1fd7100b",
          },
        }
      )
      .subscribe((resData) => {
        console.log(resData);
      });
  }

  //   fetch("https://travelpayouts-travelpayouts-flight-data-v1.p.rapidapi.com/data/en-GB/airlines.json", {

  // 	'headers': {
  // 		'x-access-token': '290991',
  // 		'x-rapidapi-host': 'travelpayouts-travelpayouts-flight-data-v1.p.rapidapi.com',
  // 		'x-rapidapi-key': 'be728614bbmsh6dadf2afc1b8328p1b438ejsn975a1fd7100b'
  // 	}
  // })
  // .then(response => {
  // 	console.log(response);
  // })
  // .catch(err => {
  // 	console.log(err);
  // });
}
