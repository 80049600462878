import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { AppRoutingModule } from "./app-routing.module";
import { MaterialModule } from "./material.module";
import { AppComponent } from "./app.component";
import { FlightsComponent } from "./flights/flights.component";
import { HotelsComponent } from "./hotels/hotels.component";
import { AirlinesComponent } from "./airlines/airlines.component";
import { ShopComponent } from "./shop/shop.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { PolicyComponent } from "./policy/policy.component";
import { TermsandconditionsComponent } from "./termsandconditions/termsandconditions.component";
import { AffiliateComponent } from "./affiliate/affiliate.component";
import { PlaceComponent } from "./place/place.component";
import { PlaceDetailComponent } from "./place/place-detail/place-detail.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";

@NgModule({
  declarations: [
    AppComponent,
    FlightsComponent,
    HotelsComponent,
    AirlinesComponent,
    ShopComponent,
    HeaderComponent,
    FooterComponent,
    PolicyComponent,
    TermsandconditionsComponent,
    AffiliateComponent,
    PlaceComponent,
    PlaceDetailComponent,
    NotfoundComponent,
    AboutComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
