<div *ngFor="let place of placeObjData | keyvalue">
  <!-- Main Block -->
  <div class="container-fluid p-0">
    <div class="card bg-dark text-white">
      <img [src]="bannerImg" class="card-img" alt="{{ bannerImg }}" />

      <div
        class="card-img-overlay d-flex justify-content-center align-items-center flex-column"
      >
        <h5 class="display-2 text-capitalize">
          {{ place.value.mainData.cityName }}
        </h5>
        <p class="card-text">{{ place.value.mainData.subOne }}</p>
        <p class="card-text">{{ place.value.mainData.subTwo }}</p>
      </div>
    </div>
  </div>
  <!-- Main Block -->

  <section class="container-fulid mb-5 mt-5">
    <div class="row">
      <div class="col-md-10 col-11 mx-auto">
        <div class="row">
          <!-- left Block -->
          <div class="col-md-9">
            <h2 class="head-2 mb-2">Top sights</h2>
            <p class="muted">Based on traveler visits and local insights</p>

            <div class="card-columns mt-5">
              <div
                class="card shadow-sm p-1 mb-5 bg-white rounded"
                *ngFor="let item of place.value.topSights"
              >
                <div class="card-body">
                  <h5 class="card-title">{{ item.name }}</h5>
                  <p class="card-text">
                    Rating: {{ item.rating }}, Reviews: {{ item.users }}
                  </p>
                  <p class="card-text">{{ item.desc }}</p>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-8 col-10 mx-auto mb-5">
                <a
                  target="_blank"
                  href="https://c146.travelpayouts.com/click?shmarker=290991&promo_id=4424&source_type=banner&type=click"
                  ><img
                    src="https://c146.travelpayouts.com/content?promo_id=4424&shmarker=290991&type=init"
                    alt="Cheap Airline Deals"
                    width="100%"
                    height="100"
                /></a>
              </div>
            </div>
          </div>
          <!-- /left Block -->

          <!-- Right Block -->
          <div class="col-md-3">
            <div class="widget">
              <div class="head-2">Deals and Discounts</div>
              <a
                target="_blank"
                href="https://c146.travelpayouts.com/click?shmarker=290991&promo_id=4423&source_type=banner&type=click"
                ><img
                  src="https://c146.travelpayouts.com/content?promo_id=4423&shmarker=290991&type=init"
                  alt="See the world for less"
                  width="100%"
                  height="250"
              /></a>

              <br /><br />

              <div class="head-2">Car Rental</div>

              <a
                target="_blank"
                href="https://c117.travelpayouts.com/click?shmarker=290991&promo_id=4339&source_type=banner&type=click"
                ><img
                  src="https://c117.travelpayouts.com/content?promo_id=4339&shmarker=290991&type=init"
                  alt="Car Rental Deals 300x250"
                  width="100%"
                  height="250"
              /></a>

              <div class="p-3 text-center">
                <p class="mb-4">
                  We get commissions for purchases made through links in this
                  post.
                </p>
                <p>
                  For any assistance, <br />
                  reach flightreservationsdesk customer care @ <br />
                  <b> 1800-2368-480</b>
                </p>
              </div>
            </div>
          </div>
          <!-- Right Block -->
        </div>
      </div>
    </div>
  </section>
</div>
