<footer class="container-fulid text-light">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="header-title">Contact Us</div>
        <br />
        <a class="header-title" routerLink="/"> Flight Reservations Desk </a>
        <!-- <div class="header-title">Contact Us</div> -->
        <div class="header-title">Address:</div>
        <address>
          S - 4/1354 <br />
          R. K Puram <br />
          New Delhi<br />
          South West Delhi <br />
          Delhi <br />
          India - 110022
        </address>
        <!-- <address>
          109-555 Foster Ave <br />
          Coquitlam <br />
          BC V3J 0B6<br />
          Vancouver <br />
          Canada
        </address> -->

        <div>
          <div class="header-title">Phone:</div>
          <a href="tel:+18778040909">Toll free: <b>1877-804-0909</b></a>
          <br />
          <br />
          <!-- <br />
          <div class="header-title">Email:</div>

          <a href="mailto:support@flightreservationsdesk.com">
            <i class="fa fa-envelope"></i>
            support@flightreservationsdesk.com</a
          >
          <br /><br /> -->
        </div>
      </div>
      <div class="col-md-8">
        <div>
          <div class="footerNav ml-md-auto ml-lg-auto">
            <div class="header-title">Helpful Links</div>
            <br />
            <ul>
              <li>
                <a routerLink="/airlines-flights">Airlines Flights</a>
              </li>
              <li>
                <a routerLink="/about">About</a>
              </li>
              <!-- <li>
                <a routerLink="/contact">Contact</a>
              </li> -->
              <li>
                <a routerLink="/policy" class="text-white">Privacy Policy</a>
              </li>
              <li>
                <a routerLink="/termsandconditions">Terms and Conditions</a>
              </li>
              <li>
                <a routerLink="/affiliate"
                  >Affiliate Link Disclosure and Advertisements</a
                >
              </li>
            </ul>
          </div>
        </div>

        <hr />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 col-lg-8 col-12 mx-auto">
      <div class="d-flex text-center text-muted justify-content-center">
        <p class="text-center w-full">
          As a travelpayouts affiliate, flightreservationsdesk.com, earns from
          qualifying purchases. <br />
          Thank you for your support!
        </p>
      </div>
      <div class="d-flex text-center text-muted">
        <p>
          Bear in mind that some of the links in this post are affiliate links
          and if you go through them to make a purchase I will earn a
          commission. Keep in mind that I link these companies and their
          products because of their quality and not because of the commission I
          receive from your purchases. The decision is yours, and whether or not
          you decide to buy something is completely up to you.
          <br /><br />
          &copy; 2024 flightreservationsdesk.com All Rights Reserved by Javatech
          Tours Private Limited.
        </p>
      </div>
    </div>
  </div>
</footer>
