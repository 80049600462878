import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-shop",
  templateUrl: "./shop.component.html",
  styleUrls: ["./shop.component.scss"],
})
export class ShopComponent implements OnInit {
  travelAccessories: any;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private http: HttpClient
  ) {
    this.fetchProducts();
  }

  ngOnInit() {
    this.titleService.setTitle(
      "flightreservationsdesk: essential travel accessories"
    );
    this.meta.addTag({
      name: "author",
      content: "Sanjay Kumar",
    });
    this.meta.updateTag({
      name: "description",
      content:
        "flightreservationsdesk help people to plan their route" +
        " flightreservationsdesk help people to plan their route",
    });
  }

  // fetchProducts() {
  //   this.http.get(
  //     'https://amazon-product-reviews-keywords.p.rapidapi.com/product/search?country=US&keyword=all',
  //     {
  //       'headers': {
  //         'x-rapidapi-host': 'amazon-product-reviews-keywords.p.rapidapi.com',
  //         'x-rapidapi-key': 'be728614bbmsh6dadf2afc1b8328p1b438ejsn975a1fd7100b'
  //       }
  //     }
  //   ).subscribe(resData => {
  //     console.log(resData);
  //     this.travelAccessories = resData;
  //   });
  // }

  fetchProducts() {
    this.http
      .get("./../assets/json/products/travelproducts.json")
      .subscribe((resData) => {
        this.travelAccessories = resData;
      });
  }
}
