<!-- Nav Bar -->
<header class="container-fulid fixed-top">
  <nav
    class="navbar d-flex justify-content-lg-between justify-content-center navbar-expand-md navbar-dark nav-bg shadow-sm"
  >
    <a class="navbar-brand ml-3" routerLink="/">
      <span>flight</span>reservationsdesk
    </a>
    <!-- <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <div>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item home">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>

        <li class="toll">
          <a href="tel:+18778040909">Toll free: <b>1877-804-0909</b></a>
        </li>
      </ul>
    </div>
    <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>

        <li class="toll">
          <a href="tel:+18778040909">Toll free: <b>1877-804-0909</b></a>
        </li>
      </ul>
    </div> -->
  </nav>
</header>
