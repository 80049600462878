<div class="container">
  <h1 class="mt-5 pt-5 mb-5">About Us</h1>
  <p>
    Flight Reservations Desk, owned and operated by Javatech Tours Private
    Limited, a Delhi, India-based online travel agency, holds all rights
    reserved. For the past six years, we've specialized in providing vacation
    packages and travel essentials to customers in India, USA and Canada. Our
    offerings include travel accessories, flight tickets, hotel accommodations,
    and car rentals.
  </p>

  <p>
    Flight Reservations Desk in particular function as an authorized online
    flight ticket booking website, earning commissions on sales. Our website,
    https://flightreservationsdesk.com/home, has been affiliated with
    "Travelpayouts," a reputable US-based affiliate program, for over two years.
    We facilitate flight ticket sales through both online channels and toll-free
    phone support, available 24/7.
  </p>
  <p class="mb-5 pb-5">
    Flight Reservations Desk is constantly working on making flight tickets
    available to the customers in the best price and quality. Our team of
    dedicated travel experts is committed to providing you with seamless and
    hassle-free travel planning.
  </p>
</div>
