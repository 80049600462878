import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConversionService {
  setConversion(email: string, phone: string): void {
    window.uetq = window.uetq || [];
    window.uetq.push("set", { pid: { em: email, ph: phone } });
  }
}
