<section class="container-fulid mt-5">
  <div class="row">
    <div class="col-md-8 col-11 mx-auto mt-5 mb-5 content-block">
      <h2>Disclaimer: Advertisements, Affiliate Links</h2>
      <p>
        Running a website isn’t cheap and definitely isn’t free so to provide
        content that we don’t charge for we use various advertisements,
        affiliate links and sponsorship throughout our site. Here you’ll find
        out why we have advertisements, affiliate links and accept sponsorship
        with information on particular brands we work with.
      </p>

      <h2>Why we have ads, affiliate links and take sponsorship</h2>
      <p>
        Before we go into all these revenue sources here is why we need to do
        this:
      </p>

      <ul>
        <li>So as to pay writers for new articles.</li>
        <li>
          To cover the cost of hosting our website, as well as a CDN (content
          delivery network) and other associated costs with website hosting.
        </li>
        <li>To allow us to invest in templates, themes and software.</li>
        <li>So as to pay for graphics and photo imagery as required.</li>
      </ul>
      <h2>Advertisements</h2>
      <p>
        Our main provider of advertising is using the Google Display Network
        “Google Adsense” – you’ll find these adverts throughout our site in the
        sidebar, and within articles. Where we have these articles you’ll see
        the text “Advertisement” either above or below the advert. If you click
        on these we’ll then get a fee and we also make a little income from
        impressions.
      </p>

      <p>
        To create a better and more relevant experience we have disabled all
        sensitive topics so you shouldn’t see anything regarding cosmetic
        surgery, drugs, borrowing money etc. If you do happen to come across an
        advert that upsets or offends you then please let us know and we’ll
        consider putting in a request not to show that ad again.
      </p>

      <p>
        As well as Adsense we may occasionally implementation banners and other
        adverts. Should you wish you can also completely disable ads. Visit our
        privacy policy to learn about ad blocking.
      </p>

      <h2>Affiliate links</h2>
      <p>
        Affiliate links are urls within our site which then open a page on
        another website. These tend to be for products and services and if you
        click through one of these links and purchase we’ll get a small
        commission at no extra cost to you.
      </p>

      <b>
        We only recommend products and services that we have used or trust and
        would never recommend anything which we don’t believe in.
      </b>

      <p>A few affiliate partners we work with include:</p>
      <ul>
        <li>travelpayouts(aviasales)</li>
        <!-- <li>cheapoair</li>
        <li>Jetradar</li>
        <li>Hotellook</li> -->
      </ul>
      <p>
        And various others. If you click on a link and it takes you to a product
        or service page then it’s best to assume we may get a commission.
      </p>

      <p>
        flightreservationsdesk.com is also a participant in the Amazon Services
        LLC Associates Program, an affiliate advertising program designed to
        provide a means for sites to earn advertising fees by advertising and
        linking to Amazon.com.
      </p>

      <h2>Summary</h2>
      <p>
        To reiterate we have ads so as to run this website and invest in content
        and improvements to the site infrastructure. If we don’t have any income
        sources then this wouldn’t be possible.
      </p>
      <p>
        Should you decide to make a purchase via advertisements you see on our
        site, or through an affiliate link then this is your decision and we are
        not liable whatsoever for any problems (if any) that you may have as a
        result.
      </p>

      <p>
        Thank you for your understanding and support and we hope you find our
        content helpful.
      </p>
    </div>
  </div>
</section>
